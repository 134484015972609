function defaultToast(toast) {
  toast.style.position = "fixed";
  toast.style.top = "4rem";
  toast.style.left = "50%";
  toast.style.textAlign = "center";
  toast.style.padding = "1rem";
  toast.style.borderRadius = "50px";
  toast.style.textTransform = "uppercase";
  toast.style.fontSize = "14px";
  toast.style.fontFamily = "Inter UI Black";
  toast.style.color = "#FFFFFF";
  toast.style.minWidth = "250px";
  toast.style.maxWidth = "400px";
  toast.style.transform = "translate(-50%, 0)";
  return toast;
}

export function success(toast) {
  const defaultToastt = defaultToast(toast);
  defaultToastt.style.background = "rgba(0, 0, 0, 0.9)";
  defaultToastt.dataset.test = "toast-success";
  return defaultToastt;
}

export function error(toast) {
  const defaultToastt = defaultToast(toast);
  defaultToastt.style.background = "rgba(224, 32, 32, 0.95)";
  defaultToastt.dataset.test = "toast-error";
  return defaultToastt;
}

export function warning(toast) {
  const defaultToastt = defaultToast(toast);
  defaultToastt.style.background = "rgba(115, 115, 115, 0.9)";
  defaultToastt.dataset.test = "toast-success";
  return defaultToastt;
}

export function createToast(type, { message }) {
  let toast = document.getElementById("toast-eyes");
  if (toast) toast.remove();
  toast = document.createElement("div");
  toast.id = "toast-eyes";
  toast.innerHTML = message;
  toast = type(toast);
  document.body.appendChild(toast);
  setTimeout(() => toast.remove(), 3000);
}
