import AreqTools from "@letsgoi/areq";

const { create } = AreqTools;

const tokens = {
  access: null,
  refresh: null,
  expiresIn: 600,
  lastRequest: Date.now(),
};

const settings = {
  apiHost: import.meta.env.VITE_APP_API_HOST,
  tokens,
};

const areq = create(settings);

export const resolveData =
  (fallBack = []) =>
  ({ response }) =>
    response.data ?? fallBack;

export default areq;
