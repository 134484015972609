export function generateSizeMap(size) {
  if (size.length > 0) {
    return `${size[0]}x${size[1]}`;
  }
  throw Error("Size must have 2 values: WIDTH AND HEIGHT");
}

export function generateMarkers(markers) {
  const markersParsed = markers.map((marker) => {
    const icon = `url-${marker.icon}`;
    const lonLat = `(${marker.coordinates[0]},${marker.coordinates[1]})`;
    let markerUrl = "";
    if (marker.icon) {
      markerUrl += icon;
    }
    if (marker.coordinates[0] && marker.coordinates[1]) {
      markerUrl += lonLat;
    }
    return markerUrl;
  });
  return markersParsed.join("");
}

export function generatePosition(coordinates, zoom) {
  const lonLat = `${coordinates[0]},${coordinates[1]},${zoom}`;
  return lonLat;
}
