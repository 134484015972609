export default {
  app: {
    name: "Chin",
  },
  delivery: {
    date: {
      title: "Sigue tu envío",
      meta: "Tocaremos el timbre de tu puerta el",
    },
    change: {
      button: " Cambia la fecha de entrega",
    },
    logs: {
      from: "Envía",
      ref: "Ref",
      expected: "Fecha estimada",
    },
    contact: {
      title: "¿Tienes problemas con la entrega? Contacta con GOI",
    },
  },
  schedule: {
    button_cancel: "Cancelar",
    button_accept: "Aceptar",
    select_day: "Selecciona un día",
    range_time: "La entrega se realizará entre 9:00 y 21:00 horas",
    applying: "Aplicando cambio...",
    button_understood: "Entendido",
    error_title: "Se ha producido un error",
    error_body:
      "Sentimos comunicarte que en este momento no se ha podido completar el cambio de fecha. Vuelve a intentarlo nuevamente y si el fallo persiste, llámanos por teléfono para ayudarte.",
    error_body_generic:
      "Sentimos comunicarte que en este momento se ha producido un error interno. Vuelve a intentarlo nuevamente y si el fallo persiste, llámanos por teléfono para ayudarte.",
  },
  "not-found": {
    title: "La página está perdida, pero no tu pedido.",
    subtitle: "El link a la página de seguimiento no funciona correctamente.",
    content:
      "Esto tal vez se deba a que el nº de referencia sea erróneo o tal vez se deba a un problema técnico. Por favor, inténtalo de nuevo más tarde si crees que se debe a un error técnico, y en caso de seguir produciéndose, ponte en contacto con nosotros y trateremos de ayudarte:",
    email: "support{'@'}letsgoi.com",
  },
  status: {
    default: "Pendiente de recogida",
    delivered: "Entregado",
    in_transit: "En reparto",
    picked_up: "Pedido recogido",
    inNetwork: "Viajando a hub de destino",
    inDestinationWarehouse: "En hub de destino",
  },
  groups: {
    picked_up: "Información de pedido recibida",
    in_transit: "De camino a delegación de destino",
    out_for_delivery: "En proceso de reparto",
    delivered: "Entrega",
  },
  events: {
    order_in_transit_platform: "Tu pedido está en plataforma de tránsito",
    client_absent: "Cliente ausente",
    merch_picked_up: "Mercancía recogida",
    cancelled_order: "Pedido cancelado",
    closed_order: "Pedido cerrado",
    arrived_at_distribution_warehouse:
      "Ha llegado a nuestro almacén de destino",
    incorrect_delivery_details: "Datos de entrega incorrectos",
    order_on_way_to_goi_platform: "Pedido de camino hacia plataforma GOI",
    order_delivered: "¡Entregado!",
    merch_waiting: "Esperando mercancía",
    order_in_delivery: "Pedido en reparto",
    no_contact: "No contacto con destinatario",
    in_warehouse_for_delivery: "En almacén de destino",
    label_estimated_date: "Fecha estimada",
    label_failed_delivery: "Entrega Fallida",
    recipient_postpones_delivery: "Destinatario aplaza entrega",
  },
  card_success: {
    title: "Cambiado correctamente",
    subtitle: "La fecha de entrega se ha cambiado para el día:",
    button: "Volver a seguimiento de pedido",
    check: "Puedes comprobar el cambio en la fecha de entrega aproximada.",
  },
};
