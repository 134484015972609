import { createApp } from "vue";
import i18n from "@/common/helpers/i18n";
import * as Sentry from "@sentry/vue";
import Toast from "@/common/plugins/toast";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// styles
import "@/styles/main.scss";
import "./twstyles/styles.css";

const app = createApp(App);

if (import.meta.env.VITE_APP_TRACKING_ENV === "production") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);
app.use(store);
app.use(Toast);
app.use(i18n);

app.mount("#app");
