export const EMPTY_STRING = "";

export const DATE_TEMPLATE = "yyyy-MM-dd";
export const DATE_TIME_TEMPLATE = "yyyy-MM-dd HH:mm";
export const DATE_TIME_TEMPLATE_SECONDS = "yyyy-MM-dd HH:mm:ss";
export const DATE_TEXT_TEMPLATE = "EEEE, d LLL'";

export const LOCALE_TEMPLATES = {
  en: {
    DATE_TEMPLATE: "yyyy-MM-dd",
    DATE_TIME_TEMPLATE: "yyyy-MM-dd HH:mm",
    DATE_TIME_TEMPLATE_SECONDS: "yyyy-MM-dd HH:mm:ss",
    DATE_TEXT_TEMPLATE: "EEEE, d 'at' LLL",
  },
  es: {
    DATE_TEMPLATE: "dd-MM-yyyy",
    DATE_TIME_TEMPLATE: "dd-MM-yyyy HH:mm",
    DATE_TIME_TEMPLATE_SECONDS: "dd-MM-yyyy HH:mm:ss",
    DATE_TEXT_TEMPLATE: "EEEE, d 'de' LLL",
  },
};
