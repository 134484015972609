export const TYPE_MAP = "light-v10";
export const ZOOM_MIN_DEFAULT = 4;
export const ZOOM_MIN = 6;
export const ZOOM_MAX_DEFAULT = 16;
export const ZOOM_MAX = 18;
export const ICON_MAP = `${window.location.origin}/marker.png`;
export const LATITUDE_DEFAULT = "40.4399";
export const LONGITUDE_DEFAULT = "-3.6832";

export const ROUTE_NAME_DELIVERY = "delivery";
export const ROUTE_NAME_DELIVERY_OPERATION = "deliveryOperation";
