import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { es, enUS } from "date-fns/locale";
import {
  DATE_TEXT_TEMPLATE,
  LOCALE_TEMPLATES,
} from "@/common/helpers/constants";
import i18n from "@/common/helpers/i18n";
import { EMPTY_STRING } from "@/common/helpers/constants";

const locales = { es, en: enUS };

export function convToTextDates(
  date,
  { dateFormat = DATE_TEXT_TEMPLATE, locale = "en" }
) {
  return format(parseISO(date), dateFormat, { locale: locales[locale] });
}

export function getDateTimeLocalize(date, template = "DATE_TIME_TEMPLATE") {
  if (!date || !LOCALE_TEMPLATES[i18n.global.locale]) return EMPTY_STRING;
  return convToTextDates(date, {
    dateFormat: LOCALE_TEMPLATES[i18n.global.locale][template],
    locale: i18n.global.locale,
  });
}
