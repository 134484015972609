export default {
  app: {
    name: "Chin",
  },
  delivery: {
    date: {
      title: "Track your order",
      meta: "We will arrive on",
    },
    change: {
      button: "Change delivery date",
    },
    logs: {
      from: "From",
      ref: "Ref",
      expected: "Expected on",
    },
    contact: {
      title: "Problems with your delivery? Contact GOI",
    },
  },
  schedule: {
    button_cancel: "Cancel",
    button_accept: "Accept",
    select_day: "Select a day",
    range_time: "The delivery will be executed between 9:00 a.m. and 9:00 p.m.",
    applying: "Applying change...",
    button_understood: "Understood",
    error_title: "An error has occurred",
    error_body:
      "We are sorry to inform you that at this time the date change could not be completed. Please try again and if the failure persists, call us by phone to help you.",
    error_body_generic:
      "We are sorry to inform you that an internal error has occurred at this time. Please try again and if the failure persists, call us to help you.",
  },
  "not-found": {
    title: "Page missing, your order is not.",
    subtitle: "The link to track your delivery is broken.",
    content:
      "Maybe the reference order is wrong, maybe there is technical error. Please try again and if the error persist you can contact us at",
    email: "support{'@'}letsgoi.com",
  },
  status: {
    default: "Pending pick up",
    delivered: "Delivered",
    in_transit: "In transit",
    picked_up: "Order picked up",
    inNetwork: "Traveling to the destination hub",
    inDestinationWarehouse: "In destination hub",
  },
  groups: {
    picked_up: "Order information received",
    in_transit: "On the way to destination branch",
    out_for_delivery: "Out for delivery",
    delivered: "Delivery",
  },
  events: {
    order_in_transit_platform: "Your order is on the transit platform",
    client_absent: "Client is absent",
    merch_picked_up: "Merchandise picked up",
    cancelled_order: "Order cancelled",
    closed_order: "Order closed",
    arrived_at_distribution_warehouse:
      "Has arrived at our destination warehouse",
    incorrect_delivery_details: "Delivery details incorrect",
    order_on_way_to_goi_platform: "Order on its way to GOI platform",
    order_delivered: "Delivered!",
    merch_waiting: "Waiting for merchandise",
    order_in_delivery: "Order in delivery",
    no_contact: "No contact with recipient",
    in_warehouse_for_delivery: "In destination warehouse",
    label_estimated_date: "Estimated date",
    label_failed_delivery: "Failed delivery",
    recipient_postpones_delivery: "Recipient postpones delivery",
  },
  card_success: {
    title: "Changed successfully",
    subtitle: "The delivery date has been changed to:",
    button: "Return to order tracking",
    check: "You can check the change in the estimated delivery date.",
  },
};
