import { createRouter, createWebHistory } from "vue-router";

const Detail = () => import("@/modules/delivery/DetailView.vue");
const ScheduleDelivery = () =>
  import("@/modules/delivery/ScheduleDelivery.vue");
const NotFound = () => import("@/common/components/NotFound.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/orders/:reference",
      name: "delivery",
      component: Detail,
    },
    {
      path: "/orders/:reference/schedule-delivery",
      name: "schedule",
      component: ScheduleDelivery,
    },
    {
      path: "/op/:operation_reference/:client_order_id",
      name: "deliveryOperation",
      component: Detail,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "notFound",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { reference } = to.params;
  if (reference) {
    document.title = `${reference} | Chin`;
  }

  next();
});

export default router;
