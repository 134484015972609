import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { MAPBOX, ORDERS, OPERATIONS, TIMELINE_GROUPS } from "./paths";

const { setJsonHeader, setHeaders, setBody, GET, POST, jsonData } = AreqTools;

export function showOrder(reference) {
  return areq()
    .then(setHeaders())
    .then(setJsonHeader)
    .then(GET(`${ORDERS}/${reference}`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}
export function showOrderByOperation(opReference, clientOrderId) {
  return areq()
    .then(setHeaders())
    .then(setJsonHeader)
    .then(GET(`${OPERATIONS}/${opReference}${ORDERS}/${clientOrderId}`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function indexAvailableDays(orderReference) {
  return areq()
    .then(setHeaders())
    .then(setJsonHeader)
    .then(GET(`${ORDERS}/${orderReference}/available-days`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function changeDeliveryTime({ orderReference, date }) {
  return areq()
    .then(setHeaders())
    .then(setJsonHeader)
    .then(setBody(date))
    .then(POST(`${ORDERS}/${orderReference}/delivery-time`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function indexMap(type, markers, position, size) {
  return new Promise((resolve) => {
    resolve(
      `${MAPBOX}/${type}/static/${markers}/${position}/${size}?access_token=${
        import.meta.env.VITE_APP_MAPBOX_TOKEN
      }`
    );
  });
}

export function indexTimelimeGroups() {
  return areq()
    .then(setHeaders())
    .then(setJsonHeader)
    .then(GET(`${TIMELINE_GROUPS}`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}
