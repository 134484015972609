import { indexMap } from "@/common/helpers/api/services";

import {
  generateSizeMap,
  generateMarkers,
  generatePosition,
} from "@/common/helpers/maps";
import { TYPE_MAP, ICON_MAP } from "./constants";

export function requestMap(coordinates, size, zoom) {
  const markersArray = [
    {
      coordinates,
      icon: encodeURIComponent(ICON_MAP),
    },
  ];

  const markersMap = generateMarkers(markersArray);
  const positionMap = generatePosition(coordinates, zoom);
  const sizeMap = generateSizeMap(size);

  return indexMap(TYPE_MAP, markersMap, positionMap, sizeMap)
    .then((url) => url)
    .catch((error) => {
      throw error;
    });
}
