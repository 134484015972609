import { createI18n } from "vue-i18n";
import es from "./lang/es";
import en from "./lang/en";
import dateTimeFormats from "./datetime/datetime.json";

function getBrowserLanguage() {
  const languageBrowser =
    window.navigator.userLanguage || window.navigator.language;
  const str = languageBrowser.split("-");
  return str[0] ?? "en";
}

const locale = getBrowserLanguage();

const i18n = createI18n({
  locale,
  fallbackLocale: "en",
  messages: { en, es },
  dateTimeFormats,
});

export default i18n;
