import { createStore } from "vuex";
import DeliveryStore from "@/modules/delivery/store";

const modules = {
  delivery: DeliveryStore,
};

export default createStore({
  strict: !import.meta.env.PROD,
  modules,
});
